import React from 'react'
import Topbar from './components/Topbar'
import Bottombar from './components/Bottombar'
import './css/privacy.css'
import {Link} from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className='privacyPolicy-container'>
      <div className='title-section'> 
        <h1>Demic Privacy Policy</h1>
        <h6>Last Updated on May 29, 2021</h6>
      </div>
      <div className='main-section'>
        <h4>Introduction</h4>
        <p>In order to provide you with an optimal, customized experience on our app, we ask that you provide us with some basic information about yourself and what you're looking for. We want to be fully transparent about exactly what data we collect and how we use it, so we've detailed this information below.</p>

        <h4>What We Collect</h4>
        <h5>User data</h5>
        <li>Account registration data (required)</li>
        <ul>
          <li>We only collect the bare minimum of registration data in order to ensure a secure creation of an account.</li>
          <li>Account registration data includes your name, university, university email address, and password.</li>
        </ul>
        <li>Profile data</li>
        <ul>
          <li>Profile data is viewable by your classmates. Excluding profile picture, pronouns, and bio, profile data is used to calculate compatibility with classmates.</li>
          <li>Basic profile data (required)</li>
          <ul>
            <li>Basic profile data includes year in school, major, and enrolled course titles.</li>
          </ul>
          <li>Other profile data (optional)</li>
          <ul>
            <li>Other profile data includes profile picture, pronouns, bio, ranked learning styles, ranked communication modes, general weekly availability, and interests.</li>
          </ul>
        </ul>
        <li>Preferences data (required)</li>
        <ul>
          <li>Preference data tells us about who you are looking for. We store the default preference settings for your account upon its creation, and update our storage with any changes you make to the preference settings.</li>
          <li>Preference settings include the option to match with others, the option to look for a friend, study buddy, or both, and the option to match with others based on year, major, learning style, preferred mode of communication, availability, and/or interests.</li>
        </ul>

        <h5>Usage Data</h5>
        <li>We collect anonymous analytics to observe how users interact with various features in the app. No personally identifiable information (name, email) is collected for analytics.</li>


        <h4>How We Use Data</h4>
        <li>We use account registration data to provide you with our service and communicate with you via email with responses to feedback or important alerts regarding your account.</li>
        <li>We use profile and preferences data to personalize your matches.</li>
        <li>If you are reported by another user for violating our User Guidelines, we may investigate your profile and/or message history with user who reported you. Depending on the severity of the report, we may suspend your account until our investigation is complete.</li>

        <h4>How we protect your data</h4>
        <p>Your data is protected by the robust security measures taken by Firebase and Google. Although we take steps to protect your data, please remember that there is no method that guarantees absolute security of electronically-transmitted data.</p>
        
        <h4>Service Providers</h4>
        <p>We share your data with the following third-party service providers who allow us to assist us with mobile development and deployment, cloud storage, hosting, analytics, and data security.</p>
        <li>Google Play Services. See Google Play Services's policy <a href='https://policies.google.com/privacy' target="_blank" rel="noopener noreferrer">here</a>.</li>
        <li>Firebase. See Firebase's privacy policy <a href='https://firebase.google.com/support/privacy' target="_blank" rel="noopener noreferrer">here</a>.</li>
        <li>Expo. See Expo's privacy policy <a href='https://expo.dev/privacy-explained' target="_blank" rel="noopener noreferrer">here</a>.</li>

        <h4>Your Rights</h4>
        <li>You can access the data you entered at any time within the app.</li>
        <li>You can delete your account within the app, which will remove all your personal information from our possession.</li>

        <h4>Children's Privacy</h4>
        <p>Our service is directed towards and restricted to university students with a @wisc.edu email. We do not knowingly collect information from users under the age of 13. If you suspect that a user is under the age of 13, please use the reporting mechanism available through the service.</p>

        <h4>Future Changes</h4>
        <p>We may change this privacy policy from time to time. We will notify you via email about any updates to this private policy.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions about this privacy policy, please contact us at <a href = "mailto:support@demicapp.com?subject = Feedback&body = Message">support@demicapp.com</a>.</p>
      </div>
      <Topbar/>
      <Bottombar/>
    </div>
  )
}

export default PrivacyPolicy