import React from 'react'
import '../css/topbar.css'
import logo from '../images/demic-logo.png'
import { useNavigate } from "react-router-dom";

function Topbar() {
  // 👇️ navigate to /home
  let navigate = useNavigate(); 
  const routeChange = () => {
    let path = '/'; 
    navigate(path);
  }
  return (
    <div className='topbar-container'>
      <img className='demic-logo' src={logo} alt='demic-logo' onClick={routeChange}/>
    </div>
  )
}

export default Topbar