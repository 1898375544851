import React from 'react'
import Topbar from './components/Topbar'
import Bottombar from './components/Bottombar'
import './css/terms.css'
import {Link} from 'react-router-dom';

function termOfService() {

  return (
  <div className='termOfService-container'>
    <div className='title-section'> 
      <h1>Demic Terms of Service</h1>
      <h6>Last Updated on February 22, 2021</h6>
    </div>
    <div className='main-section'>
      <p>READ THESE TERMS OF SERVICE CAREFULLY. THESE TERMS OF SERVICE CREATE A CONTRACT BETWEEN YOU AND US. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, DO NOT USE OUR APP.</p>
      
      <h4>Introduction</h4>
      <p>Below are the terms which you agree to comply with by using our app.</p>
      
      <h4>Eligibility</h4>
      <p>You are at least 18 years old and are capable of forming a binding contract with Demic LLC.</p>
      
      <h4>User Guidelines</h4>
      <p>Demic reserves the right to suspend or terminate accounts which do any of the following:</p>
      
      <li>Offend, harass, defame or otherwise mistreat other users</li>
      <li>Create a profile or sending a message which is obscene/pornographic/sexual in any way</li>
      <li>Provide fraudulent information when creating an account/profile, including impersonation/identity theft</li>
      <li>Upload copyrighted material</li>
      <li>Abuse the service (saving changes to your profile or preferences an unreasonable amount/frequency)</li>
      <li>Intend to damage the software</li>
      <li>Use the app as a platform for any academic misconduct as defined by your university, who will be notified if we detect your involvement in such misconduct</li>
      <li>Use the app as a platform for any illegal operations</li>
      
      <h4>Other User's Content and Actions</h4>
      <p>We are not responsible for the content of other users' profiles and messages. If you believe any user is violating the user guidelines, please report them by clicking the information icon at the top right of their profile.</p>

      <h4>Limitation of Liability</h4>
      <p>IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFIT DAMAGES ARISING FROM YOUR USE OF OUR SITE OR PARTNER SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR USING OUR SITE DURING THE TERM OF MEMBERSHIP.</p>

      <h4>Privacy</h4>
      <p>You agree to our collection and use of your data as described in our <Link to="/privacy">Privacy Policy</Link>.</p>

      <h4>Payments</h4>
      <p>Demic and all the features that it offers are available at no cost to all users on the App Store and Google Play Store.</p>

      <h4>Copyright and Trademark</h4>
      <p>This software, service, name (DEMIC™), and logo are copyrighted and trademarked. By using this app, you agree to not reverse engineer or use our copyrighted material.</p>

      <h4>Future Changes</h4>
      <p>We will notify you via email about any updates to this terms of service.</p>

      <h4>Contact Us</h4>
      <p>If you have any questions about this terms of service, please contact us at <a href = "mailto:support@demicapp.com?subject = Feedback&body = Message">support@demicapp.com</a>.</p>

    </div>

    <Topbar/>
    <Bottombar/>

  </div>
    
  )
}

export default termOfService