import Customize from '../images/mockup.png'

export const images = [
    { 
        title: "1. Customize",
        description: "Customize classmate discovery. Are you looking to study or make friends? What do you want to have in common?",
        image: Customize
    },
    {
        title: "2. Discover",
        description: "Discover students who are in 1+ of your classes. Classmates who best match your preferences will show up first!",
        image: Customize
    },
    {
        title: "3. Match",
        description: "If you and a classmate both say “YES” on each other’s profiles, you will be matched!",
        image: Customize
    },
    {
        title: "4. Chat",
        description: "Once you match with someone you can direct message and add each other to group chats!",
        image: Customize
    }

];