import React from 'react'
import '../css/bottombar.css'
import {useNavigate} from 'react-router-dom';
import facebook from '../images/facebook-logo.png'
import instagram from '../images/Instagram-logo.png'
import twitter from '../images/Twitter-logo.png'
import linkedin from '../images/linkedin-logo.png'

function Bottombar() {
  const navigate = useNavigate();

  const navigateToTerms = () => {
    // 👇️ navigate to /terms of service
    navigate('/terms');
  };
  const navigateToPrivacy = () => {
    // 👇️ navigate to /privacy of policy
    navigate('/privacy');
  };

  return (
    <div className='bottombar-container'>
        <div className='links'>
          <a className='link' href='https://www.facebook.com/getdemic/' target='_blank' rel="noopener noreferrer">
            <img src={facebook} alt='facebook logo'style={{height: '1rem', margin: '0.8rem'}}/>
          </a>
          <a className='link' href='https://www.instagram.com/demic.app/' target='_blank' rel="noopener noreferrer">
            <img src={instagram} alt='instagram logo'style={{height: '1rem', margin: '0.8rem'}}/>
          </a>
          <a className='link' href='https://twitter.com/demic_app' target='_blank' rel="noopener noreferrer">
            <img src={twitter} alt='twitter logo'style={{height: '1rem', margin: '0.8rem'}}/>
          </a>
          <a className='link' href='https://www.linkedin.com/company/demic-app/' target='_blank' rel="noopener noreferrer">
            <img src={linkedin} alt='Linkedin logo'style={{height: '1rem', margin: '0.8rem'}}/>
          </a>
        </div>
        <div className='links'>
          <div className='link' onClick={navigateToTerms}>Terms of Service</div>
          <p>|</p>
          <div className='link' onClick={navigateToPrivacy}>Privacy Policy</div>
          <p>|</p>
          <a className='link' href = "mailto:support@demicapp.com?subject = Feedback&body = Message">Contact: support@demicapp.com</a>
        </div>
        <p> © 2021 Demic LLC. All Rights Reserved. </p>
    </div>
  )
}

export default Bottombar