import React, { useState } from 'react'
import '../css/carousel.css'

import {images} from './CarouselData'
function Carousel() {
  const [currImg, setCurrImg] = useState(0);

  return (
    <div className='carousel-container'>
      <div className='prev' onClick={()=>{currImg>0 && setCurrImg(currImg-1)}}> &lt; </div>

      <div className='carousel'>
        <img className='image' src={images[currImg].image}/>
        <div className='detail'>
          <div className='detail-title'> {images[currImg].title} </div>
          <div className='detail-description'> {images[currImg].description} </div>
        </div>
      </div>

      <div className='next' onClick={()=>{currImg<images.length-1 && setCurrImg(currImg+1)}}> &gt; </div>
    </div>
  )
}

export default Carousel