import React from 'react'
import '../css/card.css'

function Card(props) {
  const imgFirst = props.imgFirst;
  if (imgFirst == "true") {
    return (
      <div className='Card'>
          <img src={props.img}/>
          <div className='discription' >
              <div className='discription-title'>{props.title}</div>
              <div className='discription-content'> {props.content} </div>
          </div>
      </div>
    )
  }
  else {
    return (
      <div className='Card' >
          <div className='discription' >
              <div className='discription-title'>{props.title}</div>
              <div className='discription-content'> {props.content} </div>
          </div>
          <img src={props.img}/>
      </div>
    )
  }
}

export default Card