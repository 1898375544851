import './css/App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import TermOfService from './TermOfService';
import PrivacyPolicy from './PrivacyPolicy'

function App() {
  return (
    <div className="App">

      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/terms' element={<TermOfService/>}/>
        <Route path='/privacy' element={<PrivacyPolicy/>}/>
      </Routes>
      
    </div>
  );
}

export default App;
