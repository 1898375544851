import React, { useEffect } from 'react'
import './css/home.css'
import Topbar from './components/Topbar'
import Bottombar from './components/Bottombar'
import Card from './components/Card'
import Carousel from './components/Carousel'

import courses from './images/courses-illustration.svg'
import interacting from './images/interacting.svg'
import AppStoreQR from './images/qr-app-store.png'
import GooglePlayQR from './images/qr-app-store.png'
import mockup from './images/mockup.png'
import AppStoreBaner from './images/app-store.svg'
import GooglePlayBanner from './images/google-play-badge.png'
import login from './images/login-illustration.svg'

import 'aos/dist/aos.css';
import Aos from 'aos'

function Home() {
  // create a scroll to top button
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  useEffect(() => {
    Aos.init({duration: 1000,
              mirror: true
    });
  }, []);



  return (
  <div>
    <div className='splash'>
      <div className='flyer-container' data-aos="fade-up">
        <div className='flyer-left' data-aos="fade-right" data-aos-delay="300">
          <img src={mockup} alt="app demo picture"/>
        </div>
        <div className='flyer-right'>
          <h1 data-aos="fade-left" data-aos-delay="200">Demic</h1>
          <h3 data-aos="fade-up" data-aos-delay="500">Connect with classmates at University of Wisconsin-Madison</h3> 
          <div className='download-section' data-aos="fade" data-aos-delay="700" data-aos-duration="2000">
            <h2 className='GetTheApp'>Get The App</h2>
            <div className='QRCode-section'>
              <div className='AppStore'>
                <img src={AppStoreQR} alt="appstore QRcode"/>
                <h5>App Store</h5>
              </div>
              <div className='GooglePlay'>
                <img src={GooglePlayQR} alt="googleplay QRcode"/>
                <h5>Google Play</h5>
              </div>
            </div>
          </div>
          <div className='download-section-mobile'>
            <a href='https://apps.apple.com/us/app/demic-connect-with-classmates/id1571082551' target='_blank' rel="noopener noreferrer">
              <img src={AppStoreBaner} alt="appstore downlaod"/>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.demic' target='_blank' rel="noopener noreferrer">
              <img src={GooglePlayBanner} alt="googleplay downlaod"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className='connect'>
      <div className='slogan' data-aos="fade-right">
        Connect with Classmates
      </div>

      <div className='body' >
        <p data-aos="fade-left">
          As college students ourselves, we know how important classmate connections are to academic and social success. But many times we come into our classes not knowing anyone, and it's hard to build connections in a classroom setting - especially at a large university like UW-Madison. Instructors can't practically provide every student with in-depth support, and even if they could, it's intimidating to ask questions to someone with a PhD on the subject!    
        </p>
        <p data-aos="fade-right"><b>To solve this, we have created a platform for our peers to find and form 
          connections with their classmates.</b></p>
      </div>
    </div>
    
    <div data-aos="fade-up">
      <Card img={courses} 
            title="Pass your classes"
            content="Find project partners, study buddies, or just classmates you can reach out to when you’re stuck"
            imgFirst={true}
            
      />
    </div>
    
    <div data-aos="fade-up">
      <Card img={interacting} 
          title="Make new friends"
          content="Find people with similar interests who are also looking to make friends"
          imgFirst={false}
          
      />
    </div>

   
    <div className='HowItWorks' data-aos="fade-up" data-aos-mirror="false">
      How it Works?
    </div>

    <div data-aos="fade" data-aos-delay="0" data-aos-duration="1000">
      <Carousel/>
    </div>

    <div className='bottomBackground' data-aos="fade" data-aos-delay="100" data-aos-duration="3000">
      <div className='bottomBody'>
        <h1 data-aos="fade-down">What are you waiting for?</h1>
        
        <button className='button' onClick={goToTop} data-aos="fade-up">
          <b>JOIN NOW</b>
        </button>
        
      </div>
      <img src={login} alt="join us" data-aos="fade-up"/>
    </div>

    <Topbar/>
    <Bottombar/>
  </div>
  )
}

export default Home